import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '400'
  }
}

const PinelImpots = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "pinel/pinel-reduction-impots.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle>
        La réduction d’impôt
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        En termes de chiffres, les déductions fiscales attenantes aux investissements loi Pinel peuvent monter jusqu’à 21% !
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Bien entendu, cette réduction d’impôt liée à un investissement Pinel n’est pas accordée sans contrepartie. En effet, les investisseurs sont tenus de louer leur bien immobilier neuf durant 6 ans minimum, et ce à un loyer plafonné.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default PinelImpots
