import React from 'react'
import styled from 'styled-components'

import StyledList from '../../atoms/StyledList'
import LaunchButton from '../../atoms/LaunchButton'
import HomeContainer from '../../atoms/HomeContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
`

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 24px;
`

const PinelBien = () => (
  <HomeContainer max={1000}>
    <HomeSectionTitle tag='h3'>
      Quel bien immobilier peut faire l’objet d’un investissement Pinel&nbsp;?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Tous les biens immobiliers ne sont pas éligibles au dispositif loi Pinel. Comment savoir si un bien peut être concerné par un investissement locatif Pinel ? Quelles sont les conditions d’éligibilité de ce dispositif fiscal&nbsp;?
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Il apparaît que, pour faire l’objet d’un investissement Pinel, il faut :
    </HomeSectionDescription>
    <StyledList>
      <li>
        <strong>Acheter un bien immobilier neuf ou investir dans un bien ancien</strong> qui doit être entièrement réhabilité
      </li>
      <li>
        <strong>Réaliser un achat dans une zone dite tendue,</strong> donc où l’offre immobilière est tendue
      </li>
      <li>
        <strong>Ne pas payer plus de 5 500 euros le mètre carré</strong> de surface habitable
      </li>
      <li>
        <strong>Prévoir de louer l’appartement vide,</strong> non meublé et pour habitation principale
      </li>
      <li>
        <strong>Mettre le bien en location</strong> au plus tard un an après son acquisition ou sa livraison (pour les achats sur plan)
      </li>
    </StyledList>
    <BottomText>
      <strong>Si vous cherchez un bien immobilier neuf pour réaliser un investissement loi Pinel, </strong><br /> faites-vous accompagner par nos experts
    </BottomText>
    <StyledButtonContainer>
      <LaunchButton title='Lancez votre recherche' />
    </StyledButtonContainer>
  </HomeContainer>
)

export default PinelBien
