import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import media from '../utils/media.js'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import PinelBiens from '../components/organisms/PinelBiens'
import PinelZones from '../components/organisms/PinelZones'
import PinelImpots from '../components/organisms/PinelImpots'
import PinelResume from '../components/organisms/PinelResume'
import PinelStatus from '../components/organisms/PinelStatus'
import SiteSection from '../components/atoms/SiteSection'
import PinelBiensZones from '../components/organisms/PinelBiensZone'
import PinelTopSection from '../components/organisms/PinelTopSection'
import PinelDeductionLoyers from '../components/organisms/PinelDeductionLoyers'
import PinelDeductionLoyersDetails from '../components/organisms/PinelDeductionLoyersDetails'

const TITLE = 'Investir avec la loi Pinel : ce qu\'il faut savoir'
const DESCRIPTION = 'Envie de réaliser un investissement loi Pinel ? Folhomee vous accompagne, laissez-vous guider par notre équipe d\'experts.'

const StyledSiteSection = styled(SiteSection)`
  ${media.greaterThan('xl')`
    height: 400px;
  `}
`

const InvestissementPinel = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <PinelTopSection />
    <SiteSection id='pinel-status'>
      <PinelStatus />
    </SiteSection>
    <StyledSiteSection id='pinel-impots'>
      <PinelImpots />
    </StyledSiteSection>
    <SiteSection id='pinel-criteres'>
      <PinelResume />
    </SiteSection>
    <StyledSiteSection id='pinel-bien-et-zones'>
      <PinelBiensZones />
    </StyledSiteSection>
    <SiteSection id='pinel-biens'>
      <PinelBiens />
    </SiteSection>
    <SiteSection id='pinel-zones'>
      <PinelZones />
    </SiteSection>
    <SiteSection id='pinel-deduction-loyers'>
      <PinelDeductionLoyers />
    </SiteSection>
    <SiteSection id='pinel-deduction-loyers-details'>
      <PinelDeductionLoyersDetails />
    </SiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

InvestissementPinel.propTypes = {
  location: PropTypes.object.isRequired
}

export default InvestissementPinel
