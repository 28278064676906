import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoNew from '../../../assets/new.inline.svg'
import PictoKeys from '../../../assets/keys.inline.svg'
import PictoPiggy from '../../../assets/piggy.inline.svg'
import LaunchButton from '../../atoms/LaunchButton'
import HomeContainer from '../../atoms/HomeContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoPiggy,
  description: 'D’une possibilité de <strong>profiter de déductions fiscales</strong> avantageuses (jusqu’à 21%)'
}, {
  icon: PictoNew,
  description: 'D’un dispositif disponible uniquement <strong>pour un achat dans l’immobilier neuf</strong> (sauf exceptions)'
}, {
  icon: PictoKeys,
  description: '<strong>D’un engagement de la part de l’investisseur</strong> concernant la location de son bien immobilier (durant six ans au minimum)'
}]

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
  `

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 24px;
`

const PinelResume = () => (
  <HomeContainer max={870}>
    <HomeSectionTitle>
      En quelques mots :
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Si on veut résumer l’investissement loi Pinel en quelques lignes, il est possible de dire qu’il s’agit&nbsp;:
    </HomeSectionSubtitle>
    <PictoColContainer details={DETAILS_COL} />
    <BottomText>
      <strong>Vous avez besoin de conseils pour réaliser un investissement Pinel ?</strong><br /> Folhomee est à votre disposition pour vous aider.
    </BottomText>
    <StyledButtonContainer>
      <LaunchButton
        link='/services/investissement-pinel'
        title='Nous contacter' />
    </StyledButtonContainer>
  </HomeContainer>
)

export default PinelResume
