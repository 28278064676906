import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 24px
`

const Content = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Content2 = styled.div`
  margin-top: 160px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const BottomText = styled(HomeSectionDescription)`
  max-width: 700px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
`

const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  max-width: 700px;
`

const ImageContainer = styled.div`
    padding-top: 32px;

  ${media.greaterThan('md')`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 100px;
  `}
`

const PinelDeductionLoyersDetails = () => (
  <Container>
    <Content>
      <HomeSectionTitle tag='h3'>
        Quel est le montant de la déduction fiscale Pinel&nbsp;?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Comme évoqué précédemment, les personnes qui réalisent un investissement loi Pinel peuvent profiter de déductions fiscales.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Il s’agit d’ailleurs de l’essence-même de ce dispositif. Le montant de la réduction d’impôts loi Pinel dépend de la durée d’engagement de location de la part de l’acquéreur. Le tableau ci-dessous répond à la question : quel est le montant de la déduction fiscale pour un investissement Pinel ?
      </HomeSectionDescription>
      <ImageContainer>
        <StaticImage src='../../../images/pinel/pinel-deduction-table.png' alt='Les intérêts du prêt amortissable diminuent au fur et à mesure des années' />
      </ImageContainer>
      <HomeSectionDescription>
        Les investisseurs qui font le choix de s’engager sur une période locative de 9 ans profitent d’un taux plus intéressant. En effet, voici les avantages fiscaux qui s’offrent à eux :
        <StyledList>
          <li>
            <strong>Pour une période initiale de 9 ans,</strong> le taux est de 18% et la réduction d’impôt est de 2% /an
          </li>
          <li>
            <strong>Pour une prolongation de 3 ans,</strong> le taux est de 3% et la réduction d’impôt est de 1% /an
          </li>
        </StyledList>
      </HomeSectionDescription>
    </Content>
    <Content2>
      <HomeSectionTitle tag='h3'>
        Les loyers plafonnés pour un investissement loi Pinel
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Toute personne qui réalise un investissement Pinel doit mettre son bien en location. Attention, il est obligatoire de tenir compte des mentions imposées par le dispositif.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Dans les faits, il faudra respecter certains plafonds de loyer pour pouvoir profiter de tous les avantages que présente l’investissement immobilier loi Pinel.
        Il apparaît que le loyer d’un bien acheté en Pinel dépend de la zone géographique dans laquelle se trouve le bien.
      </HomeSectionDescription>
      <ImageContainer>
        <StaticImage src='../../../images/pinel/pinel-loyers.png' alt='Les intérêts du prêt amortissable diminuent au fur et à mesure des années' />
      </ImageContainer>
      <BottomText>
        <strong>L’équipe Folhomee est disponible pour vous conseiller vis-à-vis de votre investissement Pinel</strong><br />Inscrivez-vous, cela ne prend que quelques minutes&nbsp;!
      </BottomText>
    </Content2>
    <StyledButtonContainer>
      <LaunchButton title='Lancez votre recherche' />
    </StyledButtonContainer>
  </Container>
)

export default PinelDeductionLoyersDetails

