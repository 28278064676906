import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { map, isEqual, get, range } from 'lodash'


import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const ZONES = [{
  color: ({ theme }) => get(theme, 'brightOrange'),
  title: 'Zone ABis'
}, {
  color: ({ theme }) => get(theme, 'otherBlue'),
  title: 'Zone A'
}, {
  color: ({ theme }) => get(theme, 'skyBlue'),
  title: 'Zone B1'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const RessourcesContainer = styled.div`
  display: flex;
  margin: 40px 0;

  ${media.lessThan('md')`
    flex-direction: column;
  `}
`

const ImagesMapLegend = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const ImageContainer = styled(ImagesMapLegend)`
  justify-content: center;
`

const StyledHomeSectionSubtitle = styled(HomeSectionSubtitle)`
  font-size: 22px;
  font-weight: bold;
`

const MapDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-bottom : 20px;
`

const DetailColor = styled.div`
  display: flex;
  flex-direction: column;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  ${({ color, theme }) => isEqual(color, get(theme, 'white')) ? `border: 1px solid ${get(theme, 'blue')}` : ''}
`

const DetailText = styled.div`
  display: flex;
  flex-direction: column;
`

const DetailContainer = styled.div`
  display: flex;
`
const DetailTitle = styled(HomeSectionDescription)`
  margin: 0;
`

const ColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
  ${media.lessThan('md')`
    flex-direction: column;
 `}
`
const Column = styled.div`
  margin-bottom: 24px;
  ${media.greaterThan('md')`
    width: 50%;
    padding-right: 129px;
`}
`

const PinelZones = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Comprendre les zones du dispositif Pinel
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le dispositif Pinel découpe la France en 3 zones géographiques, éligibles ou non.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      En effet, toutes les zones géographiques ne présentent pas les mêmes enjeux en termes de besoins de logement, d’où le fait qu’elles ne soient pas toutes éligibles.
    </HomeSectionDescription>
    <RessourcesContainer>
      <ImageContainer>
        <StaticImage src='../../../images/pinel/pinel-zones.png' alt='Les zones du dispositif Pinel' height='300' />
      </ImageContainer>
      <ImagesMapLegend>
        <HomeSectionSubtitle>
          Voici la liste des zones Pinel :
        </HomeSectionSubtitle>
        <MapDetailsContainer>
          {map(ZONES, ({ color, title }) => (
            <DetailContainer>
              <div>
                <DetailColor color={color} />
              </div>
              <DetailText>
                <DetailTitle>
                  {title}
                </DetailTitle>
              </DetailText>
            </DetailContainer>
          ))}
        </MapDetailsContainer>
        <HomeSectionDescription>
          Dans chaque zone, il existe des villes qui peuvent faire l’objet d’un investissement Pinel, et d’autres qui ne peuvent pas. Vous trouverez ci-dessous des exemples de départements constituant les zones Pinel.
        </HomeSectionDescription>
      </ImagesMapLegend>
    </RessourcesContainer>
    <ColumnLayout>
      <Column>
        <StyledHomeSectionSubtitle>
          La zone A Bis
        </StyledHomeSectionSubtitle>
        <HomeSectionDescription>
          concerne principalement Paris et ses alentours comme, par exemple :<br></br>les Yvelines (78), les Hauts-de-Seine (92), le Val d’Oise (95), …
        </HomeSectionDescription>
      </Column>
      <Column>
        <StyledHomeSectionSubtitle>
          La zone A
        </StyledHomeSectionSubtitle>
        <HomeSectionDescription>
          elle comprend de nombreux départements. Ceux-ci vont de l’Ain (01) à la Corse du Sud (2A) en passant par la Seine-et-Marne (77).
        </HomeSectionDescription>
      </Column>
    </ColumnLayout>
    <ColumnLayout>
      <Column>
        <StyledHomeSectionSubtitle>
          La zone B1
        </StyledHomeSectionSubtitle>
        <HomeSectionDescription>
          elle englobe des villes des départements de l’Aisne (02), du Calvados (14) ou encore du Gard (30). Bien d’autres départements sont concernés par la zone B1.
        </HomeSectionDescription>
      </Column>
    </ColumnLayout>
  </Container>
)

export default PinelZones
