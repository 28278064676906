import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '400'
  }
}

const PinelBiensZones = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "pinel/pinel-biens-eligibles.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      reverse={true}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle tag='h2'>
        Les biens éligibles et les zones Pinel
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Tous les biens immobiliers ne sont pas éligibles au dispositif loi Pinel.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Folhomee vous présente les conditions d’éligibilité et les zones géographiques concernées par ce type d’investissement immobilier locatif. Pour tout savoir sur le dispositif Pinel, des biens éligibles aux zones concernées, c’est ici.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default PinelBiensZones
