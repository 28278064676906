import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PinelImage from '../../molecules/InvestissementPinelImage'
import TopSection from '../../molecules/TopSection'
import InvestissementPinel from '../../molecules/InvestissementPinel'

const StyledPinelImage = styled(PinelImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 585px; 
    top: 220px;
  `}
`

const PinelTopSection = () => (
  <TopSection component={StyledPinelImage}>
    <InvestissementPinel />
  </TopSection>
)

export default PinelTopSection
