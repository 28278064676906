import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const InvestissementPinelImage = props => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "investissement-loi-pinel.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <GatsbyImage
      alt='Investissement loi Pinel'
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      {...props} />
  )
}

const StyledImage = styled(InvestissementPinelImage)`
  position: absolute;
  width: 1090px;
  height: auto;
  top: 39%;
  right: 0;
  transform: translateY(-35%);
`

export default StyledImage
