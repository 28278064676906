import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 675px;
  margin: auto;

  ${media.lessThan('sm')`
    padding-top: 100px;
    padding-bottom: 80px;
  `}

  ${media.greaterThan('xl')`
    max-width: 520px;
    margin-right: 55%;
  `}
`

const InvestissementPinel = () => (
  <StyledContainer>
    <Header max={100}>
      Investissement loi Pinel
    </Header>
    <Subtitle>
      Parmi les types d’investissement immobilier envisageables en France, on retrouve l’investissement loi Pinel. Il s’agit en fait d’un dispositif fiscal grâce auquel il est possible de profiter de réductions d’impôts.
    </Subtitle>
    <Description>
      Folhomee est à même de vous accompagner dans votre recherche de bien immobilier, que vous souhaitiez réaliser un investissement loi Pinel ou tout autre type d’investissement immobilier. Toutes les informations que vous cherchez sur l’investissement Pinel se trouvent ici.
    </Description>
  </StyledContainer>
)

export default InvestissementPinel
