import React from 'react'

import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const PinelStatus = () => (
  <HomeContainer max={875}>
    <HomeSectionTitle tag='h2'>
      Qu'est-ce que la loi Pinel&nbsp;?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Avant de se lancer dans un investissement Pinel, il est important de savoir de quoi il s’agit véritablement. Il apparaît en fait que la loi Pinel est un dispositif qui vise à rendre davantage accessible l’investissement locatif.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Dans les faits, cette aide est proposée aux investisseurs et elle intervient dans une dynamique de soutien des constructions immobilières. Cette dynamique de soutien se vérifie lorsque l’on sait que le dispositif Pinel ne s’applique qu’aux investissements locatifs dans le neuf. Ainsi, la loi Pinel soutient les personnes qui souhaitent investir dans l’immobilier neuf pour favoriser la construction de nouveaux logements.
    </HomeSectionDescription>
  </HomeContainer>
)

export default PinelStatus
