import styled from 'styled-components'
import PropTypes from 'prop-types'

import media from '../../../utils/media'

const Container = styled.div`
  width: 100%;
  max-width: ${({ max }) => max}px;
  margin: 0 auto;
  padding: 0 24px;


  ${media.lessThan('sm')`
    padding: 0px 24px;
  `}

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}
  ${media.greaterThan('md')`
    padding: 0 72px;
  `}
  ${media.greaterThan('lg')`
    padding: 0 120px;
  `}
`

Container.propTypes = {
  max: PropTypes.number
}

Container.defaultProps = {
  max: 1400
}

export default Container
